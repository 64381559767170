<template>
  <div style="min-height: 100vh;overflow-y: scroll;background-color: #0E0E0E;">
    <div class="week_bg" style="background-image: url(img/seventhMoon/top_bg.png);">
        <div class="week_back" style="background-image: url(img/seventhMoon/w_back.png);" @click="goBack"></div>
      <!-- <div class="week_beself" style="background-image: url(img/seventhMoon/week_bottom.png);"></div> -->
    </div>
    <div class="week_rule" style="background-image: url(img/seventhMoon/week_rule.png);"></div>
    <div class="week_rule_2" style="background-image: url(img/seventhMoon/week_rule_2.png);"></div>
      <div class="bottom_tip">本活动最终解释权归光恋所有</div>
    
  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/seventh.css"

export default {
  name: 'weekStarRule',
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    goBack(){
        this.$router.go(-1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
